import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;
const Quote = ({ children }) => (
  <div className="story-quote light-text">{children}</div>
);

const StoryPage = ({ data }) => {
  const post = data.contentfulUnfluxStory;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    },
  };

  return (
    <Layout>
      <SEO
        title={post.storyTitle}
        description={"The Unflux is the meeting place for people, products, and great design"}
      />
      <section className="slide whiteSlide kenBurns">
        <div className="content">
          <div className="container">
            <div>
              <div className="story-heading">
                <div className="fix-7-12">
                  <h2 className="ae-1 normal margin-bottom-2">
                    {post.storyTitle}
                  </h2>
                </div>
                <div className="ae-1 story-close-btn">
                  <a href="/learn">
                    <img src="/assets/img/cancel-x-icon.png" />
                  </a>
                </div>
              </div>
              <div className="ae-2 story-content story-image">
                <img src={post.storyHeroImage.file.url} />
              </div>
              <div className="ae-2 story-content">
                <div className="story-text">
                  {documentToReactComponents(
                    JSON.parse(post.storyText.raw),
                    options
                  )}
                  <div className="story-controls">
                    <div className="story-author-box">
                      <span className="author-image">
                        <img alt="Author" src={post.authorImage.file.url} />
                      </span>
                      <div className="author-detail">
                        <div className="author-name">{post.authorName}</div>
                        <div className="author-position">
                          {post.authorRole}, {post.authorCompany}
                        </div>
                      </div>
                    </div>
                    <a className="story-share" href="#">
                      <span>Share</span>
                      <img src="/assets/img/story-share.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StoryPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulUnfluxStory(slug: { eq: $slug }) {
      storyTitle
      updatedAt(formatString: "MMMM Do, YYYY")
      storyHeroImage {
        file {
          url
        }
      }
      storyText {
        raw
      }
      authorName
      authorRole
      authorImage {
        file {
          url
        }
      }
      authorCompany
      authorCompanyImage {
        file {
          url
        }
      }
    }
  }
`;
